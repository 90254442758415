import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["remindAdminsButton", "remindMemberButton", "transmitReportButton"];

	async remindAdmins({ params: { id } }) {
		const spinner = this.remindAdminsButtonTarget.getElementsByClassName("fa-spinner")[0];
		spinner.classList.remove("hidden");
		this.remindAdminsButtonTarget.disabled = true;

		const request = await this.turboStreamRequest("/pending_reports/remind_admins", "POST", { company_id: id });

		if (request.ok) {
			toastr.info("Reminder has been sent");
		} else {
			bootbox.alert('<span class="text-danger">' + request.statusText + "</span>");
		}
		spinner.classList.add("hidden");
		this.remindAdminsButtonTarget.disabled = false;
	}

	async remindMember({ params: { memberId } }) {
		const spinner = this.remindMemberButtonTarget.getElementsByClassName("fa-spinner")[0];
		spinner.classList.remove("hidden");
		this.remindMemberButtonTarget.disabled = true;

		const request = await this.turboStreamRequest("/pending_reports/remind_member", "POST", { company_member_id: memberId });

		if (request.ok) {
			toastr.info("Reminder has been sent");
		} else {
			bootbox.alert('<span class="text-danger">' + request.statusText + "</span>");
		}
		spinner.classList.add("hidden");
		this.remindMemberButtonTarget.disabled = false;
	}

	async transmitReport({ params: { companyId } }) {
		const spinner = this.transmitReportButtonTarget.getElementsByClassName("fa-spinner")[0];
		const _this = this;

		await bootbox.confirm({
			message: "By clicking on 'Submit and File' you are confirming that your BOI report is true, correct and complete and in accordance with our" + "<a href='/terms' target='_blank'>" + " Terms of Service " + "</a>" + "and that FinCEN Report Company, LLC is authorized by you to file this BOI report with FinCEN via electronic means and to submit your name to FinCEN as the filer of this BOI report.<br><br>Submitting your report will add it to the queue for processing which may take up to 24 hours.  You will receive an email confirmation when your report is filed.",
			buttons: {
				confirm: {
					label: "Submit and File",
					className: "btn-primary",
				},
				cancel: {
					label: "Cancel",
					className: "btn-secondary",
				},
			},
			callback: async function (isConfirmed) {
				if (!isConfirmed) return;

				spinner?.classList.remove("hidden");
				_this.transmitReportButtonTarget.disabled = true;

				const request = await _this.turboStreamRequest("/pending_reports", "POST", { company_id: companyId });
				if (request.ok) {
					toastr.info("Report has been transmitted");
					const reportStatus = document.getElementById("report-status");
					reportStatus.innerText = "Report is Pending Submission to FinCEN.  Please allow 24 hours for processing.";
					document.getElementById("previous-filing")?.remove();
				} else {
					bootbox.alert('<span class="text-danger">' + request.statusText + "</span>");
					_this.transmitReportButtonTarget.disabled = true;
				}
				spinner?.classList.add("hidden");
			},
		});
	}

	async turboStreamRequest(url, method, data = {}) {
		const response = await fetch(url, {
			method,
			body: JSON.stringify(data),
			headers: {
				"X-CSRF-Token": this.csrfToken,
				Accept: "text/vnd.turbo-stream.html",
				"Content-Type": "application/json",
			},
		}).then((r) => r);

		return response;
	}

	get csrfToken() {
		return document.querySelector("[name='csrf-token']")?.content;
	}
}

//end
